export default {
  featuresOverride: 'scnt_features_override',
  abTestsOverride: 'scnt_abtests_override',
  gbOverride: 'scnt_gb_override',
  cacheOverride: 'scnt_cache',
  localeOverride: 'scnt_locale',
  mixpanelSuperProperties: 'scnt_mixpanel_super_props',
  growthbookUid: 'scnt_growthbookuid',
  resubscribeOffer: 'resubscribeOffer',
  cardUpdateOffer: 'cardUpdateOffer',
  e2eTest: 'scnt_e2e_test',
  // TODO: remove tracking when CRO-593 is over
  cookieConsentAbTest: 'scnt_cookie_banner_ab_test',
  cookieBannerLoaded: 'CQ-SCENTBIRD_ENSIGHTEN_PRIVACY_BANNER_LOADED',
  cookieBannerViewed: 'CQ-SCENTBIRD_ENSIGHTEN_PRIVACY_BANNER_VIEWED',

  isWelcomeNotificationShown: 'isWelcomeNotificationShown',

  isPutFirstInQueueModalShown: 'isPutFirstInQueueModalShown',
  isResubscribePromoModalShown: 'isAdditionalResubscribeModalShown',
  isCardUpdateModalShown: 'isCardUpdateModalShown',
  isAddToCartCaseUpsellModalShown: 'isAddToCartCaseUpsellModalShown',
  isAddToQueueCaseUpsellModalShown: 'isAddToQueueCaseUpsellModalShown',
  isHereticCaseUpsellModalShown: 'isHereticCaseUpsellModalShown',
  isStrawberryShortcakeCaseUpsellModalShown: 'isStrawberryShortcakeCaseUpsellModalShown',
  isFirstTimeVisitModalShown: 'isFirstTimeVisitModalShown',

  /**
   * Subscription flow cookies, should be removed after subscribe
   */
  // UTM for dataLayer: should exist until subsribe
  utmMediumBeforeSubscription: 'utmMediumBeforeSubscription',
  utmSourceBeforeSubscription: 'utmSourceBeforeSubscription',
  // UTM for useFlow
  utmMediumSession: 'utmMediumSession',
  utmSourceSession: 'utmSourceSession',

  fbPixelId: 'fbPixelId',
  flowName: 'flowName',
  synergyParams: 'synergyParams',
  tophatterParams: 'tophatterParams',

  // coupon cookies
  discountCoupon: 'discountCoupon',
  appliedCouponCode: 'appliedCouponCode',
  isFreeTrialCoupon: 'isFreeTrialCoupon',
  country: 'country',
  selectedPlan: 'selectedPlan',

  referrerLandingPageURL: 'referrerLandingPageURL',
  logoRedirectUrl: 'logoRedirectUrl',

  // countdown cookies
  rootCountdown: 'countdownCookie_root',
  freePerfumeCountdown: 'countdownCookie_freePerfume',
  giftSubscriptionOfferCountdown: 'countdownCookie_giftSubscriptionOffer',
  editQueueOnboardingCountdown: 'countdownCookie_editQueueOnboarding',

  // postClick test target group
  postClickGroup: 'postClickGroup',

  fromEmail: 'fromEmail',

  giftSubscriptionOffer: 'giftSubscriptionOffer',
}
